<template>
  <div>
    <navigation :left="left" :msg="msg"></navigation>
    <div class="mymsg">
      <div class="touimg">
        <img src="../../assets/userss.png" />
      </div>
      <div class="topmsg">
        <div>{{ userInfo.username }}</div>
        <div>{{ userInfo.account }}</div>
      </div>
      <div class="yaomoneyclass">
        <div>下级充值总金额</div>
        <div>
          {{ yaomoney1 }}.<span>{{ yaomoney2 }}</span>
        </div>
      </div>
    </div>
    <div class="inviteclass">
      <div class="overf">
        <div id="tipclass">
          <div
            v-for="(item, index) in yaoqinglist"
            v-bind:key="index"
            id="ttt"
            class="di1"
          >
            <div class="dian"></div>
            <div>您成功邀请{{ item.company_name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="menuclass">
      <!-- <div class="menu">
            <div class="menumsg">
                <div><img src="../../assets/menu1.png"/></div>
                <div>邀请指南</div>
            </div>
            <div><van-icon color="#C9CED6" name="arrow" /></div>
        </div> -->
      <div @click="$router.push('/invitemsg')" class="menu">
        <div class="menumsg">
          <div><img src="../../assets/menu2.png" /></div>
          <div>邀请指南</div>
        </div>
        <div><van-icon color="#C9CED6" name="arrow" /></div>
      </div>
      <div @click="$router.push('recording')" class="menu">
        <div class="menumsg">
          <div><img src="../../assets/menu3.png" /></div>
          <div>邀请记录</div>
        </div>
        <div><van-icon color="#C9CED6" name="arrow" /></div>
      </div>
      <div @click="$router.push('/myaccount')" class="menu">
        <div class="menumsg">
          <div><img src="../../assets/menu4.png" /></div>
          <div>我的账户</div>
        </div>
        <div><van-icon color="#C9CED6" name="arrow" /></div>
      </div>
      <div @click="$router.push('/userinformation')" class="menu">
        <div class="menumsg">
          <div><img src="../../assets/menu5.png" /></div>
          <div>用户设置</div>
        </div>
        <div><van-icon color="#C9CED6" name="arrow" /></div>
      </div>
      <div @click="showqrcode = true" class="menu">
        <div class="menumsg">
          <div><img src="../../assets/kefu1.png" /></div>
          <div>联系客服</div>
        </div>
        <div><van-icon color="#C9CED6" name="arrow" /></div>
      </div>
      <div @click="tuichumeth" class="menu">
        <div class="menumsg">
          <div><img src="../../assets/tuichu1.png" /></div>
          <div>安全退出</div>
        </div>
        <div><van-icon color="#C9CED6" name="arrow" /></div>
      </div>
    </div>
    <van-popup class="qrcoedclass" v-model="showqrcode">
      <div>
        <img src="../../assets/luobo2.png" />
      </div>
      <div>请扫码联系客服</div>
    </van-popup>
  </div>
</template>
<script>
import navigation from "../../components/navigation/index";
export default {
  components: {
    navigation,
  },
  data() {
    return {
      msg: "用户信息",
      left: "false",
      state: "", //是否认证/是否渠道 1-是 0-否 -1 禁用
      show: false,
      phone: localStorage.getItem("phone"),
      userInfo: "",
      showqrcode: false,
      setime: "",
      trans: 0,
      yaoqinglist: "",
      yaomoney: "00.00",
      yaomoney1: "",
      yaomoney2: "",
    };
  },
  created() {
    this.getyaomoneymeth();
  },
  mounted() {
    /**
     * 获取邀请信息
     */
    this.getyaoqingmeth();
    this.selectallmsg();
    this.setime = setInterval(() => {
      if (-parseInt(this.trans) < parseInt(this.yaoqinglist.length) / 2 - 1) {
        document.getElementById("tipclass").style.transform =
          "translateY(" + (this.trans -= 1.1) + "rem)";
      } else {
        this.trans = 0;
        document.getElementById("tipclass").style.transform =
          "translateY(" + this.trans + "rem)";
      }
    }, 3000);
  },
  beforeDestroy() {
    clearInterval(this.setime);
  },
  methods: {
    getyaomoneymeth() {
      this.$http
        .post("/firm/v1/Channel/recharge", {
          reqType: "userinfo",
        })
        .then((res) => {
          console.log("下级金额返回的值为", JSON.parse(res.data));
          this.yaomoney = JSON.parse(res.data).data.order_amount;
          this.yaomoney1 = this.yaomoney.split(".")[0];
          this.yaomoney2 = this.yaomoney.split(".")[1];
        });
    },
    getyaoqingmeth() {
      this.$http
        .post("/firm/v1/Channel/apply_list", {
          reqType: "userinfo",
          pageno: "1",
          limit: "15",
        })
        .then((res) => {
          this.yaoqinglist = JSON.parse(res.data).data.lists;
        });
    },
    /**
     * 退出登录
     */
    tuichumeth() {
      sessionStorage.clear();
      this.$toast("已退出登录");
      this.$router.push("/login");
    },
    /**
     * 查询首页信息的值
     */
    selectallmsg() {
      this.$http
        .post("/firm/v1/Channel/channel_index", {
          reqType: "userinfo",
        })
        .then((res) => {
          let allmsg = JSON.parse(res.data);
          this.userInfo = allmsg.data.userInfo;
        });
    },

    //点击取消认证
    cancle() {
      this.show = false;
    },
  },
};
</script>
<style scoped>
.yaomoneyclass {
  font-size: 0.2rem;
  margin-left: 1rem;
  color: #717172;
}
.yaomoneyclass > :nth-child(2) {
  font-size: 0.4rem;
  color: #f55613;
}
.yaomoneyclass span {
  font-size: 0.3rem;
}
#tipclass {
  transition: all 1s;
}
.overf {
  overflow: hidden;
  height: 1rem;
}
.touimg {
  display: flex;
  align-items: center;
}
.qrcoedclass {
  width: 5.44rem;
  height: 4.2rem;
  border-radius: 0.2rem;
  font-size: 0.28rem;
}
.qrcoedclass img {
  width: 1.8rem;
  height: 1.8rem;
}
.qrcoedclass > div:first-child {
  text-align: center;
  margin-top: 0.89rem;
}
.qrcoedclass > div:nth-child(2) {
  text-align: center;
  margin-top: 0.23rem;
}
#topartner {
  position: absolute;
  background-color: #ffffff;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.28rem;
  font-size: 0.36rem;
  line-height: 0.32rem;
  color: #07b4f6;
  border: none;
  padding: 0.15rem 1.62rem;
  border-radius: 1rem;
  font-weight: 500;
}
.dclss {
  width: 6.86rem;
  height: 9, 97rem;
  position: relative;
}
.theclassimg {
  width: 100%;
  height: 100%;
}
.imgdivclass {
  text-align: center;
  margin-top: 0.54rem;
}

.buab > div {
  width: 50%;
  text-align: center;
  padding: 0.26rem 0;
}
.buab > div:first-child {
  background-color: #f65a16;
  color: #ffffff;
}
.buab > div:nth-child(2) {
  background-color: #dcdcdc;
}
.buab {
  position: absolute;
  bottom: 0;
  font-size: 0.32rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.alertclass p {
  font-size: 0.34rem;
  text-align: center;
}
.van-popup--center {
  border-radius: 0.2rem;
}
.alertclass {
  width: 5.44rem;
  height: 3.88rem;
  overflow: hidden;
  position: relative;
}
.alertclass img {
  width: 1.2rem;
  height: 1.2rem;
}
.menumsg {
  display: flex;
  align-items: center;
}
.menumsg img {
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.18rem;
}
.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 0.38rem;
}
.menuclass {
  font-size: 0.3rem;
  color: #141f33;
  margin: 0 0.32rem;
  background-color: #ffffff;
  border-radius: 0.1rem;
  padding: 0.4rem 0;
  box-shadow: 0 0.05rem 0.12rem #dfdfdf;
}
.di1 {
  margin-bottom: 0.15rem;
  display: flex;
  align-items: center;
}
.di2 {
  display: flex;
  align-items: center;
}
.dian {
  width: 0.1rem;
  height: 0.1rem;
  background-color: #f55613;
  border-radius: 50%;
  margin-right: 0.1rem;
}
.inviteclass {
  margin: 0.38rem 0.32rem;
  border-radius: 0.1rem;
  background-image: url("../../assets/rec.png");
  background-size: 100% 100%;
  font-size: 0.3rem;
  padding: 0.32rem 0.38rem;
  color: #ffffff;
  height: 1rem;
}
.mymsg {
  display: flex;
  align-items: center;
  margin: 0.13rem 0.24rem;
}
.mymsg > div:first-child {
  padding: 0.13rem;
}
.mymsg > div:first-child img {
  width: 1.05rem;
  height: 1.05rem;
}
.topmsg {
  margin-left: 0.27rem;
}
.topmsg > div:first-child {
  font-size: 0.32rem;
  margin-bottom: 0.1rem;
}
.topmsg > div:nth-child(2) {
  font-size: 0.28rem;
}
</style>